<template>
    <div class="other_main">

        <div class="organization">
            <span>{{ showTopTitle }}</span>
            <!-- <span>{{ showTop ? $t('大宗货') : $t('跨境商品') }}{{ isBuy ? $t('详情') : $t('需求详情') }}</span> -->
            <div class="organization_back" @click="back">
                <div class="organization_back_icon"></div>
                {{ $t("返回") }}
            </div>
        </div>
        <div class="organization_line"></div>
        <div class="main_details_flex">
            <!-- 买家登录 查看商品 -->
            <div class="main_details_flex_1" v-if="isBuy">
                <div style="font-weight:500">{{ obj.goods_name }}
                    <div class="main_list_1_place_1_1" v-if="obj.d_category == 1">
                        <el-image :src="require('../assets/img/69@2x.png')" class="main_list_1_place_1_1_img"></el-image>
                        <span class="main_list_1_place_1_1_span">{{ obj.goods_category_name }}</span>
                    </div>
                </div>
                <div style="display:flex;height: 300px;">
                    <div style="width: 74px;text-align: center;" class="img_flex_1">
                        <div v-for="(item, index) in obj.imglist" :key="index">
                            <el-image @click="chooseImg(index)"
                                style="width: 68px; height: 68px;margin: 6px 0;border-radius: 4px;cursor: pointer;"
                                :class="index == carouselIndex ? 'carouselActive' : ''" :src="$store.state.host + '/entrance' + item.fullpath"></el-image>
                        </div>
                    </div>
                    <div style="flex:1">
                        <el-carousel :interval="5000" arrow="always" indicator-position="none" ref="carousel"
                            @change="changeIndex">
                            <el-carousel-item v-for="(item, index) in obj.imglist" :key="index" style="width:100%">
                                <el-image fit="contain" style="width: 100%; height: 300px;border-radius:4px;" :src="$store.state.host + '/entrance' + item.fullpath"></el-image>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div style="width: 200px;margin-left: 20px;position: relative;">
                        <!-- 跨境 -->
                        <template v-if="obj.d_category == 2">
                            <div style="margin-bottom:12px">
                                <span style="color:#666666;font-size:14px;">{{$t('分类')}}</span>
                                <span style="color:#333;font-size:14px;float: right;">{{ obj.goods_category_name || '-' }}</span>
                            </div>
                            <div style="margin-bottom:12px">
                                <span style="color:#666666;font-size:14px;">{{$t('产地')}}</span>
                                <span style="color:#333;font-size:14px;float: right;">{{ obj.source_area_name || '-' }}</span>
                            </div>
                            <div style="margin-bottom:12px">
                                <span style="color:#666666;font-size:14px;">{{$t('品牌')}}</span>
                                <span style="color:#333;font-size:14px;float: right;">{{ obj.brand_id_name || '-' }}</span>
                            </div>
                            <div style="margin-bottom:12px">
                                <span style="color:#666666;font-size:14px;">{{$t('商品简介')}}</span>
                                <div style="color:#333;font-size:14px;height: 30px;overflow: hidden;">{{ obj.pc_remark || '-' }}</div>
                            </div>
                            <div style="margin-bottom:12px">
                                <span style="color:#666666;font-size:14px;">{{$t('规格')}}</span>
                                <template v-if="obj.spclist && obj.spclist.length">
                                    <el-scrollbar class="spc-list" style="height: 35px;">
                                        <div style="color:#333;font-size:14px;" v-for="(item,index) in obj.spclist" :key="index" >
                                        {{ item.type_name }} &nbsp;&nbsp;&nbsp; {{ item.spc }}
                                        </div>
                                    </el-scrollbar>
                                </template>
                            </div>
                            <div style="margin-bottom:12px">
                                <span style="color:#666666;font-size:14px;cursor: pointer;" @click="openDig(obj.spclist)">{{$t('查看详情')}} </span>
                            </div>
                        </template>
                        <!-- 大宗 -->
                        <template v-else>
                            <div style="margin-bottom:12px;line-height: 12px;">
                                <span style="color:#333333ff;font-size:18px;">
                                    {{obj.tb_currency_symbol}}{{ obj.price }}
                                    <span style="color:#666666;font-size:12px;">
                                        /{{ obj.goods_unit_name || 'kg' }}
                                    </span>
                                </span>
                                <span style="color:#666666;font-size:12px;float: right;">
                                    {{ obj.rough_weight || '-' }}kg
                                </span>
                            </div>

                            <div class="organization_line" style="margin:5px 0"></div>
                            <div style="margin-bottom:12px">
                                <span style="color:#666666;font-size:14px;">{{$t('品名')}}</span>
                                <span style="color:#333;font-size:14px;float: right;">{{ obj.name_name || '-' }}</span>
                            </div>
                            <div style="margin-bottom:12px">
                                <span style="color:#666666;font-size:14px;">{{$t('品种')}}</span>
                                <span style="color:#333;font-size:14px;float: right;">{{ obj.brand_id_name || '-' }}</span>
                            </div>
                            <div style="margin-bottom:12px">
                                <span style="color:#666666;font-size:14px;">{{$t('等级')}}</span>
                                <span style="color:#333;font-size:14px;float: right;">{{ obj.glevel || '-' }}</span>
                            </div>
                            <div style="margin-bottom:12px">
                                <span style="color:#666666;font-size:14px;">{{$t('处理方式')}}</span>
                                <span style="color:#333;font-size:14px;float: right;">{{ obj.hmethod || '-' }}</span>
                            </div>
                            <div style="margin-bottom:12px">
                                <span style="color:#666666;font-size:14px;">{{$t('产地')}}</span>
                                <span style="color:#333;font-size:14px;float: right;">{{ obj.source_area_name || '-' }}</span>
                            </div>
                            <div style="margin-bottom:12px">
                                <span style="color:#666666;font-size:14px;">{{$t('产区')}}</span>
                                <span style="color:#333;font-size:14px;float: right;">{{ obj.source_city || '-' }}</span>
                            </div>
                        </template>

                        <div @click="openIm(obj)"
                            style="height:40px;line-height:40px;text-align:center;font-size:14px;background:#006bfc;border-radius:2px;font-weight:500;color:#ffffff;position:absolute;bottom: 0;width: 100%;cursor: pointer;">
                          {{$t('立即联系')}}</div>
                    </div>
                </div>
                <template v-if="obj.d_category == 2">
                    <div class="organization_line" style="margin:20px 0"></div>
                    <div class="main_details_title">{{$t('成分与材质')}}</div>
                    <div class="main_details_form">{{ obj.g_material || $t('无') }}</div>
                    <div class="main_details_title">{{$t('功效说明')}}</div>
                    <div class="main_details_form">{{ obj.g_effect || $t('无') }}</div>
                    <div class="main_details_title">{{$t('使用方法')}}</div>
                    <div class="main_details_form">{{ obj.g_usemethod || $t('无') }}</div>
                    <div class="main_details_title">{{$t('注意事项')}}</div>
                    <div class="main_details_form">{{ obj.g_remark || $t('无') }}</div>
                </template>
                <template v-else>
                    <div class="organization_line" style="margin:20px 0"></div>
                    <div class="main_details_title">{{ $t('货物信息') }}</div>
                    <div class="main_details_form">
                        <div style="flex:1">
                            <span class="main_details_form_col">{{$t('体积')}}：</span>
                            <span>{{ obj.volume || '-' }}</span>
                        </div>
                        <div style="flex:1">
                            <span class="main_details_form_col">{{$t('包装类型')}}：</span>
                            <span>{{ obj.pkgtype_name || '-' }}</span>
                        </div>
                        <div style="flex:1">
                            <span class="main_details_form_col">{{$t('包装规格')}}：</span>
                            <span>{{ obj.pkgspc_name || '-' }}</span>
                        </div>
                    </div>
                    <div class="main_details_form">
                        <div style="flex:1">
                            <span class="main_details_form_col">{{$t('数量')}}</span>
                            <span>{{ obj.quantity || '-' }}</span>
                        </div>
                        <div style="flex:1">
                            <span class="main_details_form_col">{{$t('生产日期')}}：</span>
                            <span>{{ obj.proddate || '-' }}</span>
                        </div>
                        <div style="flex:1">
                            <span class="main_details_form_col">{{$t('保质期至')}}：</span>
                            <span>{{ obj.enddate || '-' }}</span>
                        </div>
                    </div>
                    <div class="main_details_form">
                        <div style="flex:1">
                            <span class="main_details_form_col">{{$t('规格')}}</span>
                            <span>{{ obj.spc_desc || '-' }}</span>
                        </div>
                    </div>
                    <div class="main_details_form">
                        <div style="flex:1">
                            <span class="main_details_form_col">{{$t('贮存方法')}}：</span>
                            <span>{{ obj.savemethod || '-' }}</span>
                        </div>
                    </div>
                    <div class="main_details_title">{{ $t('运输相关信息') }}</div>
                    <div class="main_details_form">
                        <div style="flex:1">
                            <span class="main_details_form_col">{{$t('运输方式')}}：</span>
                            <span>{{ obj.tranmode_name || '-' }}</span>
                        </div>
                    </div>
                </template>
            </div>
            <!-- 卖家登录查看需求 -->
            <div v-else class="main_details_flex_1">
                <div style="align-items:center;display: flex;">
                    <el-image v-if="isBuy" :src="$store.state.host + '/entrance' + obj.good_image"
                        style="width: 68px;height:68px"></el-image>
                    <div class="main_details_flex_1_2">
                        <div style="font-weight:500;display: inline-block;margin-right: 10px;">{{ obj.goods_name }}</div>
                        <div v-if="obj.d_classify_name" class="main_list_1_place_1_1" style="margin-left:0;margin-top:12px">
                            <el-image :src="require('../assets/img/69@2x.png')"
                                class="main_list_1_place_1_1_img"></el-image>
                            <span class="main_list_1_place_1_1_span">{{ obj.d_classify_name }}</span>
                        </div>
                    </div>
                    <div class="main_details_flex_1_3" @click="openIm(obj)">{{ $t('立即联系') }}</div>
                </div>
                <div class="main_details_line"></div>
                <div class="main_details_title">{{ $t('基本信息') }}</div>
                <div class="main_details_form" v-if="obj.d_category == 1">
                    <div style="flex:1">
                        <span class="main_details_form_col">{{$t('品名')}}</span>
                        <span>{{ obj.goods_pname_name || '-' }}</span>
                    </div>
                    <div style="flex:1">
                        <span class="main_details_form_col">{{$t('品种')}}</span>
                        <span>{{ obj.brand_id_name || '-' }}</span>
                    </div>
                    <div style="flex:1">
                        <span class="main_details_form_col">{{$t('等级')}}</span>
                        <span>{{ obj.goods_level || '-' }}</span>
                    </div>
                </div>
                <div class="main_details_form" v-if="obj.d_category == 1">
                    <div style="flex:1">
                        <span class="main_details_form_col">{{$t('处理方式')}}</span>
                        <span>{{ obj.goods_process || '-' }}</span>
                    </div>
                    <div style="flex:1">
                        <span class="main_details_form_col">{{$t('产地')}}</span>
                        <span>{{ obj.source_area_name || '-' }}</span>
                    </div>
                    <div style="flex:1">
                        <span class="main_details_form_col">{{$t('产区')}}</span>
                        <span>{{ obj.source_city || '-' }}</span>
                    </div>
                </div>
                <div class="main_details_form" v-if="obj.d_category == 2">
                    <div style="flex:1">
                        <span class="main_details_form_col">{{$t('品牌')}}</span>
                        <span>{{ obj.brand_id_name || '-' }}</span>
                    </div>
                    <div style="flex:1">
                        <span class="main_details_form_col">{{$t('原产地')}}</span>
                        <span>{{ obj.source_area_name || '-' }}</span>
                    </div>
                </div>
                <div class="main_details_title">{{$t('货物信息')}}</div>
                <div class="main_details_form">
                    <!-- <div style="flex:1" v-for="(t, i) in obj.settings" :key="i">
                        <span class="main_details_form_col">{{ t.setting_name }}：</span>
                        <span>{{ t.setting_value }}</span>
                    </div> -->
                    <div style="flex:1">
                        <span class="main_details_form_col">{{$t('规格')}}</span>
                        <span>{{ obj.spc_desc || '-' }}</span>
                    </div>
                </div>
                <div class="main_details_title">{{ $t('质量标准') }}</div>
                <div class="main_details_form main_details_form_col">{{ obj.quality_standard || $t('无') }}</div>
                <div class="main_details_title">{{ $t('采购数量') }}</div>
                <div class="main_details_form">
                    <div>
                        <span class="main_details_form_col">{{ $t('采购数量') }}：</span>
                        <span>{{ obj.d_numb_des || $t('无') }}</span>
                    </div>
                </div>
                <!-- <div class="main_details_form">
                    <div>
                        <span class="main_details_form_col">{{ $t('交货期') }}：</span>
                        <span>供应商应能够在签订合同后的 [68日] 内完成交货，确保货物按时到达指定地点。</span>
                    </div>
                </div> -->
                <div class="main_details_title">{{ $t('供应商要求') }}</div>
                <div class="main_details_form main_details_form_col">{{ obj.supplier_require || $t('无') }}</div>
                <div class="main_details_title">{{ $t('其他') }}</div>
                <div class="main_details_form main_details_form_col">{{ obj.remark || $t('无') }}</div>
                <div class="main_details_foot">{{$t('感谢您对我们公司')}}</div>
            </div>
            <div class="main_details_flex_2">
                <el-image :src="require('../assets/img/34@2x.png')" style="width: 100%;height:245px"></el-image>
                <el-image :src="require('../assets/img/73@2x.png')"
                    style="width: 108px;height:30px;position: absolute;right: 0;top: 0;"></el-image>
                <el-image :src="$store.state.host + '/entrance' + (isBuy ? obj.synInfo.logo : obj.logo)"
                    style="width: 120px;height:120px;position: absolute;right: 180px;top: 54px;">
                    <div slot="error" class="image-slot">
                                                        <el-image :src="require('../assets/img/188.png')"></el-image>
                                                    </div>
                </el-image>
                <div class="main_details_flex_2_com">{{ isBuy ? obj.synInfo.corporatename : obj.corporatename }}</div>
                <!-- <div class="main_details_flex_2_no">NO.18120830182038109201</div> -->
                <!-- <div class="main_details_flex_2_img">
                    <el-image :src="require('../assets/img/67@2x.png')"  class="main_list_1_place_2_bg_3_img"></el-image>
                    <el-image :src="require('../assets/img/66@2x.png')"  class="main_list_1_place_2_bg_4_img"></el-image>
                </div> -->
                <div class="main_details_flex_2_place">
                    <div class="main_details_flex_2_place_flex">
                        <div class="main_details_flex_2_place_flex_title">{{ $t("主营业务") }}：</div>
                        <div class="main_details_flex_2_place_flex_desc">{{ isBuy ? obj.synInfo.main_business : obj.main_business }}</div>
                    </div>
                    <div class="main_details_flex_2_place_flex">
                        <div class="main_details_flex_2_place_flex_title">{{ $t("主要经营地") }}：</div>
                        <div class="main_details_flex_2_place_flex_desc">{{ isBuy ? obj.synInfo.main_location : obj.main_location }}</div>
                    </div>
                    <div class="main_details_flex_2_place_flex">
                        <div class="main_details_flex_2_place_flex_title">{{ $t("公司简介") }}：</div>
                    </div>
                    <div class="main_details_flex_2_place_descs">{{ isBuy ? obj.synInfo.company_profile : obj.company_profile }}</div>
                </div>
            </div>
        </div>

        <el-dialog :title="$t('规格详情')" :visible.sync="dialogVisible" width="600px">
            <div style="padding:24px">
                <el-table :data="tableData" style="width: 100%" header-cell-style="background:#f8f9fa;" max-height="500">
                    <el-table-column prop="type_name" :label="$t('分类名称')">
                    </el-table-column>
                    <el-table-column prop="spc" :label="$t('规格')" width="100">
                    </el-table-column>
                    <el-table-column prop="price" :label="$t('单价')" width="120">
                    </el-table-column>
                    <el-table-column prop="qty" :label="$t('数量')" width="120">
                    </el-table-column>
                </el-table>
            </div>
            <!-- <div style="text-align: center;padding: 14px 0;border-top: 1px solid #eaedf4;">
                <el-button @click="dialogVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false" size="small">确 定</el-button>
            </div> -->
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            // isBuy: false,
            carouselIndex: 0,
            dialogVisible: false,
            tableData: []
        }
    },
    props: ['obj', 'showTop', 'showTopTitle', 'isBuy'],
    mounted() {
        // this.isBuy = sessionStorage.getItem('rym_is_buyer') == 'true'
    },
    methods: {
        back() {
            this.$emit('handBack')
        },
        changeIndex(index) {
            this.carouselIndex = index
        },
        chooseImg(index) {
            this.carouselIndex = index
            this.$refs.carousel.setActiveItem(index)
        },
        openDig(val) {
            this.tableData = val
            this.dialogVisible = true
        },
        openIm(item){
            let friend_id = item.syn_code
            let user_code = sessionStorage.getItem('rym_scm')
            window.open(this.$store.state.host + '/entrance/TouristIndex?friend_id='+friend_id+"&user_code="+user_code)
        }
    }
}
</script>

<style>
.img_flex_1::-webkit-scrollbar {
    display: none;
}
</style>
<style scoped>
.img_flex_1 {
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 20px;
}

.carouselActive {
    border: 2px solid;
    border-color: #006bfc;
}

.main_details_flex {
    display: flex;
    height: calc(100% - 110px);
}

.main_details_flex_1 {
    flex: 1;
    padding: 24px 20px;
    position: relative;
    height: 100%;
    border-right: 1px solid rgba(239, 239, 239, 1);
}

.main_details_flex_2 {
    width: 480px;
    position: relative;
}

.main_details_flex_2_com {
    position: absolute;
    top: 194px;
    width: 100%;
    text-align: center;
    font-weight: 500;
    color: #333333;
    font-size: 24px;
}

.main_details_flex_2_no {
    color: #006bfc;
    font-size: 14px;
    text-align: center;
    margin-bottom: 16px;
}

.main_details_flex_2_img {
    text-align: center;
}

.main_details_flex_2_place {
    margin: 24px 40px;
    padding: 20px 24px;
    background: #f6f8fd;
    border-radius: 4px;
}

.main_details_flex_2_place_flex {
    display: flex;
    margin-bottom: 16px;
}

.main_details_flex_2_place_flex_title {
    color: #999999;
    font-size: 14px;
}

.main_details_flex_2_place_flex_desc {
    color: #333333;
    font-size: 14px;
}

.main_details_flex_2_place_descs {
    color: #333333;
    font-size: 14px;
    text-indent: 41px;
}

.main_details_flex_1_2 {
    flex: 1;
    padding: 6px 14px;
}

.main_details_flex_1_3 {
    width: 128px;
    height: 40px;
    background: #006bfc;
    border-radius: 2px;
    text-align: center;
    line-height: 40px;
    font-weight: 500;
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
}

.main_details_line {
    height: 1px;
    background: rgba(239, 239, 239, 1);
    margin-top: 24px;
}

.main_details_title {
    margin-top: 16px;
    font-weight: 500;
    color: #333333;
    font-size: 14px;
}

.main_details_form {
    margin-top: 16px;
    color: #333333;
    font-size: 14px;
    display: flex;
}

.main_details_form_col {
    color: #666666;
}

.main_details_foot {
    position: absolute;
    bottom: 24px;
    left: 20px;
    color: #666666;
    font-size: 14px;
}
</style>
<style>
.el-scrollbar__wrap { 
    height: 100%;
    overflow: scroll;
    overflow-x:auto;
}
</style>