<template>
  <div class="menu">
    <div class="menu_logo">
      <div class="menu_logo_icon"></div>
      <div class="menu_logo_span">DIIT OP</div>
    </div>
    <div :class="act == 1 ? 'menu_first menu_first_active' : 'menu_first'" @click="handAct(1)">
      <div class="menu_first_icon"></div>
      <div class="menu_first_span">{{ $t("甄选平台") }}
        <el-tooltip v-if="act == 1" effect="dark" content="甄选平台" placement="right">
          <i class="el-icon-question"></i>
        </el-tooltip>
      </div>
      <div class="menu_first_act" v-if="act == 1"></div>
    </div>
    <div class="menu_line"></div>
    <div :class="act == 2 ? 'menu_first menu_first_active' : 'menu_first'" @click="handAct(2)">
      <div class="menu_sec_icon"></div>
      <div class="menu_first_span">{{ $t("控制台") }}
        <el-tooltip v-if="act == 2"  effect="dark" content="控制台" placement="right">
          <i class="el-icon-question"></i>
        </el-tooltip>
      </div>
      <div class="menu_first_act" v-if="act == 2"></div>
    </div>
    <div class="menu_first" @click="show = !show" v-if="jurisdiction">
      <div class="menu_thr_icon"></div>
      <div class="menu_first_span">{{ $t("组织") }}</div>
      <i class="el-icon-caret-top" v-if="show"></i>
      <i class="el-icon-caret-bottom" v-else></i>
    </div>
    <div v-if="show" class="menu_place">
      <div>
        <div class="menu_place_line" style="height: 28px"></div>
        <div class="menu_place_line" style="height: 58px;position: relative;top: -8px;"></div>
        <div class="menu_place_line" style="height: 54px;position: relative;top: -16px;"></div>
      </div>
      <div>
        <div :class="act == 3 ? 'menu_second menu_first_active' : 'menu_second'" @click="handAct(3)">
          <div class="menu_first_span">{{ $t("组织架构") }}
            <el-tooltip v-if="act == 3"  effect="dark" content="组织架构" placement="right">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>
          <div class="menu_first_act" v-if="act == 3"></div>
        </div>
        <div :class="act == 5 ? 'menu_second menu_first_active' : 'menu_second'" @click="handAct(5)">
          <div>{{ $t("角色管理") }}
            <el-tooltip v-if="act == 5"  effect="dark" content="角色管理" placement="right">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>
          <div class="menu_first_act" v-if="act == 5"></div>
        </div>
        <div :class="act == 6 ? 'menu_second menu_first_active' : 'menu_second'" @click="handAct(6)">
          <div>{{ $t("用户管理") }}
            <el-tooltip v-if="act == 6"  effect="dark" content="用户管理" placement="right">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>
          <div class="menu_first_act" v-if="act == 6"></div>
        </div>
      </div>
    </div>
    <div :class="act == 4 ? 'menu_first menu_first_active' : 'menu_first'" @click="handAct(4)">
      <div class="menu_four_icon"></div>
      <div class="menu_first_span">{{ $t("设置") }}
        <el-tooltip v-if="act == 4"  effect="dark" content="设置" placement="right">
          <i class="el-icon-question"></i>
        </el-tooltip>
      </div>
      <div class="menu_first_act" v-if="act == 4"></div>
    </div>
    <div :class="act == 7 ? 'menu_first menu_first_active' : 'menu_first'" @click="handAct(7)">
      <div class="menu_seven_icon"></div>
      <div class="menu_first_span">{{ $t("签署协议") }}
        <el-tooltip v-if="act == 7"  effect="dark" content="签署协议" placement="right">
          <i class="el-icon-question"></i>
        </el-tooltip>
      </div>
      <div class="menu_first_act" v-if="act == 7"></div>
    </div>
    <div class="foot_place">
      <div class="foot_line"></div>
      <div v-if="act == 2">
        <div v-for="(item, index) in countryList" :key="item.id"
          :style="{ backgroundImage: index == country_index ? `url(${$store.state.host + '/entrance' + item.check_img})` : `url(${$store.state.host + '/entrance' + item.img})` }"
          :class="index == country_index ? 'region_active' : 'region'" @click="choose(index)">
          <div>{{ item.name }}</div>
        </div>
      </div>
      <div class="foot_img">
        <div class="foot_img_title">Help Center</div>
        <div class="foot_img_con">Having trouble in <span class="foot_img_con_white">DIIT OP</span>?</div>
        <div class="foot_img_con">Please contact us for more </div>
        <div class="foot_img_con">question</div>
          <el-popover
            placement="top-start"
            :title="$t('公司邮箱')"
            width="200"
            trigger="hover"
            :content="semail">
            
            <div class="foot_img_btn" slot="reference">
              <a href="mailto:support@diit-operation-platform.com">
                {{ $t('联系我们') }}
              </a>
            </div>
           
          </el-popover>
          
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  props: ['countryList', 'country_index'],
  data() {
    return {
      act: 2,
      show: false,
      semail: '',
      jurisdiction: false
    }
  },
  mounted() {
    if (sessionStorage.getItem('rym_index')) {
      this.handAct(sessionStorage.getItem('rym_index'))
    }

    if (sessionStorage.getItem('rym_gwCode') == 'GW0002') {
      this.jurisdiction = true
    } else {
      this.jurisdiction = false
    }

    this.init()
    
  },
  methods: {
    choose(id) {
      this.$emit('choose', id)
    },
    handAct(index) {
      this.act = index
      sessionStorage.setItem('rym_index', index)
      this.$emit('handAct', index)
    },
    getOptions(data) {
        return {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: {
                ...data,
                "snkey": sessionStorage.getItem('rym_snkey')
            },
            url: this.$store.state.host + '/entrance/sysapi',
        }
    },
    init() {
        const res = axios(this.getOptions({
            apiId: 'dlgclassrun',
            dbid: '01',
            btn: '{"cmd":"DLG","name":"获取人员基本信息","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*216;0;0"}',
            usercode: sessionStorage.getItem('rym_user'),
            env: JSON.stringify({"usercode":""})
        }))

        res.then(response => {
            this.semail = response?.data?.data?.userInfo?.semail
        })
    },
  }
}
</script>

<style scoped>
.menu {
  min-width: 220px;
  height: 100%;
  background: #121212;
  position: relative;
}

.menu_logo {
  width: calc(100% - 20px);
  padding-top: 28px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 38px;
}

.menu_logo_icon {
  background: url('../assets/img/922@2x.png') no-repeat;
  background-size: 100% 100%;
  width: 20px;
  height: 20px;
}

.menu_logo_span {
  font-weight: 700;
  color: #ffffff;
  font-size: 16px;
  margin-left: 8px;
}

.menu_first {
  display: flex;
  align-items: center;
  margin: 8px 20px;
  cursor: pointer;
  height: 40px;
  position: relative;
}

.menu_first i {
  position: absolute;
  right: 10px;
  color: #bbbfc4;
  font-size: 18px;
}

.menu_first_icon {
  background: url('../assets/img/944@2x.png') no-repeat;
  background-size: 100% 100%;
  width: 14px;
  height: 13px;
  margin-right: 13px;
  margin-left: 9px;
}

.menu_sec_icon {
  background: url('../assets/img/943@2x.png') no-repeat;
  background-size: 100% 100%;
  width: 14px;
  height: 13px;
  margin-right: 13px;
  margin-left: 9px;
}

.menu_thr_icon {
  background: url('../assets/img/946@2x.png') no-repeat;
  background-size: 100% 100%;
  width: 14px;
  height: 13px;
  margin-right: 13px;
  margin-left: 9px;
}

.menu_four_icon {
  background: url('../assets/img/945@2x.png') no-repeat;
  background-size: 100% 100%;
  width: 14px;
  height: 13px;
  margin-right: 13px;
  margin-left: 9px;
}

.menu_seven_icon {
  background: url('../assets/img/1205@2x.png') no-repeat;
  background-size: 100% 100%;
  width: 14px;
  height: 13px;
  margin-right: 13px;
  margin-left: 9px;
}

.menu_first_active {
  background: #006bfc;
  border-radius: 4px;
}

.menu_first_active .menu_first_icon {
  background: url('../assets/img/948@2x.png') no-repeat;
  background-size: 100% 100%;
  width: 14px;
  height: 13px;
  margin-right: 13px;
  margin-left: 9px;
}

.menu_first_active .menu_sec_icon {
  background: url('../assets/img/949@2x.png') no-repeat;
  background-size: 100% 100%;
  width: 14px;
  height: 13px;
  margin-right: 13px;
  margin-left: 9px;
}

.menu_first_active .menu_four_icon {
  background: url('../assets/img/951@2x.png') no-repeat;
  background-size: 100% 100%;
  width: 14px;
  height: 13px;
  margin-right: 13px;
  margin-left: 9px;
}

.menu_first_active .menu_seven_icon {
  background: url('../assets/img/1087@2x.png') no-repeat;
  background-size: 100% 100%;
  width: 14px;
  height: 13px;
  margin-right: 13px;
  margin-left: 9px;
}

.menu_first_act {
  width: 14px;
  height: 36px;
  position: absolute;
  right: -20px;
  top: 2px;
  background: url('../assets/img/947@2x.png') no-repeat;
  background-size: 100% 100%;
}

.menu_first_span {
  font-weight: 500;
  color: #ffffff;
  font-size: 14px;
}

.menu_line {
  height: 2px;
  background: #2f2f2f;
  margin: 8px 20px;
}

.menu_place {
  display: flex;
  padding-left: 35px;
}

.mt10 {
  margin-top: 10px;
}

.menu_place_line {
  width: 14px;
  height: 22px;
  border: 2px solid;
  border-color: #4b4b4b;
  border-bottom-left-radius: 8px;
  border-top: none;
  border-right: none;
}

.menu_second {
  width: 137px;
  height: 40px;
  line-height: 40px;
  font-weight: 500;
  color: #ffffff;
  font-size: 14px;
  padding-left: 12px;
  cursor: pointer;
  position: relative;
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.foot_place {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  width: calc(100% - 40px);
}

.foot_line {
  height: 2px;
  background: #2f2f2f;
  margin-bottom: 16px;
}

.foot_img {
  background: url('../assets/img/1361726015446.jpg') no-repeat;
  background-size: 100% 100%;
  height: 172px;
  margin-top: 20px;
}

.foot_img_title {
  padding: 18px 13px;
  font-weight: 700;
  color: #ffffff;
  font-size: 14px;
}

.foot_img_con {
  font-weight: 500;
  color: #c3deff;
  font-size: 12px;
  padding-left: 13px;
}

.foot_img_btn {
  margin: 13px;
  margin-top: 21px;
  background: #e3f0ff;
  border-radius: 4px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-weight: 500;
  color: #001d41;
  font-size: 14px;
}

.foot_img_con_white {
  font-weight: 900;
  color: #fff;
}

.region {
  background-size: 100% 100%;
  height: 40px;
  margin: 10px 0;
  padding-left: 14px;
  line-height: 40px;
  font-weight: 500;
  color: #333333;
  font-size: 14px;
  cursor: pointer;
}

.region_active {
  background-size: 100% 100%;
  height: 40px;
  margin: 10px 0;
  padding-left: 14px;
  line-height: 40px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  cursor: pointer;
}
</style>