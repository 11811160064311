<template>
    <div class="other_main">

        <div class="organization">
            <span>{{ table_edit ? $t('用户详情') : $t('用户管理') }}</span>
            <div class="organization_back" @click="getData(1)" v-if="table_edit">
                <div class="organization_back_icon"></div>
                {{$t("返回")}}
            </div>
            <div v-if="table_edit" class="organization_back_del" @click="getData(1)">{{$t("取消")}}</div>
            <div v-if="table_edit" class="organization_back_sure" @click="sure" v-loading="btn_loading">{{$t("保存")}}</div>
        </div>
        <div class="organization_line"></div>
        <div v-if="table_edit == 0" class="organization mb15">
            <div class="user_right_btn1" @click="del(multipleSelection2Code)">{{$t("删除")}}</div>
            <div class="user_right_btn2" @click="addUser">{{$t("新增用户")}}</div>
        </div>
        <div v-if="table_edit == 0" class="user_table">
            <el-table :data="tableData" style="width: 100%" header-cell-class-name="spenHeader" height="90%" @selection-change="handleSelectionChange2">
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column type="index" label="#" width="50"></el-table-column>
                <el-table-column :label="$t('头像')" width="120" align="center">
                    <template slot-scope="scope">
                        <el-image v-if="scope.row.photo_pic" style="width: 20px; height: 20px" :src="$store.state.host + '/entrance' + scope.row.photo_pic"></el-image>
                        <el-image v-else style="width: 20px; height: 20px" :src="require('../assets/img/131@2x.png')"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="usrcode" :label="$t('用户代码')" width="180"></el-table-column>
                <el-table-column prop="usrname" :label="$t('姓名')" width="180"></el-table-column>
                <el-table-column :label="$t('社媒账号')" width="180">
                    <template slot-scope="scope">
                        <div class="user_table_flex">
                            <div v-for="item in accountList" :key="item.sid">
                                <el-image v-if="scope.row.account_type == item.sid" style="width: 20px; height: 20px;margin-right: 5px;"
                                :src="$store.state.host + '/entrance' + item.image"></el-image>
                            </div>
                            <span>{{ scope.row.account }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="tel" :label="$t('手机号')" width="180"></el-table-column>
                <el-table-column prop="semail" :label="$t('邮箱1')" width="200"></el-table-column>
                <el-table-column prop="gwName" :label="$t('角色')" min-width="200"></el-table-column>
                <el-table-column label="操作" fixed="right" width="100" align="center">
                    <template slot-scope="scope">
                        <div class="user_table_flex">
                            <span class="user_table_edit" @click="editUser(scope.row)">{{$t("编辑")}}</span>
                            <el-dropdown trigger="click">
                                <el-image
                                    style="width: 20px; height: 20px;cursor: pointer;margin-left: 14px;position: relative;top: 3px;"
                                    :src="require('../assets/img/sa.png')"></el-image>
                                <el-dropdown-menu slot="dropdown" class="user_drop">
                                    <el-dropdown-item >
                                      <div @click="open2(scope.row)">{{$t("重置密码")}}</div>
                                    </el-dropdown-item>
                                    <el-dropdown-item>
                                        <div @click="del([scope.row.usrcode])">{{$t("删除")}}</div>
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="prev, pager, next" :total="total" class="user_page" :page-size="10" :current-page="totalPage" @current-change="getData">
            </el-pagination>
        </div>
        <div v-else class="user_detail">
            <div class="m16">
                <div class="user_detail_icon"></div>
                <div class="user_detail_label">{{$t("头像")}}</div>
                <el-upload class="user_detail_upload" action="#" :on-exceed="onExceed" :before-upload="beforeAvatarUpload"
                    :http-request="upload" list-type="picture-card" :on-preview="handlePreview" :limit="1"
                    :on-remove="handleRemove" :file-list="fileList">
                    <i class="el-icon-plus"></i>
                </el-upload>
            </div>
            <div class="user_detail_flex">
                <div class="user_detail_flex_1">
                    <div class="user_detail_icon"></div>
                    <div class="user_detail_label">{{$t("用户代码")}}</div>
                    <el-input placeholder="" v-model="from.id" :disabled="true" class="other_main_dig_flex_input">
                    </el-input>
                </div>
                <div class="user_detail_flex_1">
                    <div class="user_detail_icon"></div>
                    <div class="user_detail_label">{{$t('姓名')}}</div>
                    <el-input placeholder="" v-model="from.usrname" class="other_main_dig_flex_input">
                    </el-input>
                </div>
                <div class="user_detail_flex_1">
                    <div class="user_detail_label">{{$t("社媒账号")}}</div>
                    <el-input placeholder="" v-model="from.account" class="other_main_dig_flex_input">
                        <template slot="append">
                            <div>
                                <el-dropdown trigger="click">
                                    <div style="cursor: pointer;">
                                        <el-image
                                                style="width: 20px; height: 20px;cursor: pointer;position: relative;top: 5px;"
                                                :src="$store.state.host + '/entrance' + value"></el-image>
                                        <i class="el-icon-arrow-down el-icon--right"
                                            style="position: relative;top: -3px;"></i>
                                    </div>
                                    <el-dropdown-menu slot="dropdown" class="user_drop2">
                                        <el-dropdown-item v-for="item in accountList" :key="item.sid">
                                            <el-image
                                                style="width: 20px; height: 20px;cursor: pointer;position: relative;top: 5px;"
                                                :src="$store.state.host + '/entrance' + item.image" @click="value = item.image"></el-image>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </template>
                    </el-input>
                </div>
            </div>
            <div class="user_detail_flex">
                <div class="user_detail_flex_1">
                    <div class="user_detail_icon ml57"></div>
                    <div class="user_detail_label">{{$t("手机号")}}</div>
                    <el-input placeholder="" v-model="from.tel" class="other_main_dig_flex_input">
                    </el-input>
                </div>
                <div class="user_detail_flex_1">
                    <div class="user_detail_icon ml57"></div>
                    <div class="user_detail_label">{{$t("邮箱1")}}</div>
                    <el-input placeholder="" v-model="from.semail" class="other_main_dig_flex_input">
                    </el-input>
                </div>
                <div class="user_detail_flex_1">
                    <div class="user_detail_icon ml57"></div>
                    <div class="user_detail_label">{{$t("部门")}}</div>
                    <div @click="addClick" class="user_detail_flex_input">
                        <el-input placeholder="" readonly v-model="from.orgcodeN" class="other_main_dig_flex_input"
                            suffix-icon="el-icon-guide"></el-input>
                    </div>
                </div>
            </div>

            <!-- 用户角色 -->

            <div class="organization_line mtb24"></div>
            <div class="user_detail_table_flex">
                <div class="user_detail_circle"></div>
                <div class="user_detail_title">{{$t("配置角色")}}</div>
            </div>
            <div class="user_detail_place" >
                <div class="user_detail_place_left" >
                    <div class="user_detail_place_left_span">{{$t("区域列表")}}</div>
                    <div style="max-height: 30vh;overflow-y: auto;" class="over_div">
                        <el-tree :data="list1" node-key="id" default-expand-all  :expand-on-click-node="false" @node-click="chooseCity">
                            <span class="custom-tree-node" slot-scope="{ node, data }">
                                <span :style="{color: sysid == data.id ? '#006bfc' : ''}">{{ node.label }}</span>
                            </span>
                        </el-tree>
                    </div>
                </div>
                <div class="user_detail_place_right">
                    <el-table :data="tableData2" height="98%" @select-all="handleSelectionChange" @select="handleSelectionChange" ref="multipleTable">
                        <el-table-column type="selection" width="155" align="center"></el-table-column>
                        <el-table-column prop="gwcode" :label="$t('角色编码')" min-width="200"></el-table-column>
                        <el-table-column prop="gwname" :label="$t('角色名称')"></el-table-column>
                    </el-table>
                </div>
            </div>


        </div>

        <el-dialog :title="$t('选择部门')" :visible.sync="dialogVisible" width="460px">
            <div class="p20">
                <el-tree :data="data" ref="tree" show-checkbox check-strictly node-key="orgcode" default-expand-all :default-checked-keys="checked"
                    :props="defaultProps" @check="checkChange"></el-tree>
            </div>
            <div class="other_main_dig_line" v-if="checkedNodes.length"></div>
            <div class="tag_place">
                <el-tag v-for="tag in checkedNodes" :key="tag.orgcode" :closable="false" type="info" class="tag_m16" @close="tagClose(tag.orgcode)">
                    <span class="tag_place_span">{{ tag.orgname }}</span>
                </el-tag>
            </div>
            <div class="other_main_dig_line"></div>
            <div class="other_main_dig_btn">
                <div class="other_main_dig_btn_sure" @click="clickSure">{{$t("确认")}}</div>
                <div class="other_main_dig_btn_cancle" @click="closeDia">{{$t("取消")}}</div>
            </div>
        </el-dialog>

        <el-dialog :title="$t('重置密码')" :visible.sync="dialogVisible2" width="479px">
            <div class="other_main_dig_flex">
                <div class="other_main_dig_flex_left">
                    <span class="red">*</span>
                    {{$t("新密码")}}
                </div>
                <el-input placeholder="" show-password v-model="from2.new" class="other_main_dig_flex_input">
                </el-input>
            </div>
            <div class="other_main_dig_flex">
                <div class="other_main_dig_flex_left">
                    {{$t("确认密码")}}
                </div>
                <el-input placeholder="" show-password v-model="from2.once" class="other_main_dig_flex_input">
                </el-input>
            </div>
            <div class="other_main_dig_line"></div>
            <div class="other_main_dig_btn">
                <div class="other_main_dig_btn_sure" @click="clickSure2">{{$t("确认")}}</div>
                <div class="other_main_dig_btn_cancle" @click="dialogVisible2 = false">{{$t("取消")}}</div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            list1: [],
            dialogVisible: false,
            tableData: [],
            dialogVisible2: false,
            table_edit: 0,
            fileList: [],
            from: {},
            from2: {},
            value: 1,
            tableData2: [],
            checked: [],
            data: [], // 所有部门
            nodeList: [], // 无子集的部门数组
            checkedNodes: [],
            total: 1,
            totalPage: 1,
            sysid: '',
            multipleSelection: [],
            multipleSelection2: [],
            multipleSelection2Code: [],
            fj_root: '',
            defaultProps: {
                children: 'child',
                label: 'orgname',
                id: 'orgcode'
            },
            accountList: [],
            btn_loading: false,
            
            sysGwList:{},

            

        }
    },
    props: ['countryList'],
    mounted() {
        this.list1 = JSON.parse(JSON.stringify(this.countryList))
        this.list1.forEach((r, index) => {
            r.label = r.name
            r.children.forEach(m => {
                m.label = m.name
            })
        })
        this.init()
        this.getData(1)
        this.accountList = JSON.parse(sessionStorage.getItem('rym_account'))
    },
    methods: {
        handleSelectionChange2(val) {
            this.multipleSelection2 = val;
            this.multipleSelection2Code = this.multipleSelection2.map(item => item.usrcode)
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
            this.sysGwList[this.sysid] = val;
        },
        getRole() {
            const res = axios(this.getOptions({
                apiId: 'dlgclassrun',
                dbid: '01',
                btn: '{"cmd":"DLG","name":"获取系统下岗位列表","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*207;0;0"}',
                usercode: sessionStorage.getItem('rym_user'),
                env: JSON.stringify({ "scm": sessionStorage.getItem('rym_scm'), "sysid": this.sysid })
            }))

            res.then(response => {
                this.tableData2 = response?.data?.data?.value
                this.$nextTick(()=>{
                    this.getUser()
                })
            })
        },
        getUser() {
            let gwlist = this.sysGwList[this.sysid];
            if(gwlist && gwlist.length>0){
                let arr = [];
                this.tableData2.forEach(r => {
                    gwlist.forEach(g =>{
                        if(g.gwcode == r.gwcode){
                            arr.push(r)
                        }
                    })
                })
                arr.forEach(m => {
                    this.$refs.multipleTable.toggleRowSelection(m,true);
                })
            }else{
                const res = axios(this.getOptions({
                    apiId: 'dlgclassrun',
                    dbid: '01',
                    btn: '{"cmd":"DLG","name":"获取人员岗位","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*215;0;0"}',
                    usercode: sessionStorage.getItem('rym_user'),
                    env: JSON.stringify({ "usercode": this.from.id, "sysid": this.sysid })
                }))
                res.then(response => {
                    if (response?.data?.data.gwlist.length) {
                        let arr = []
                        this.tableData2.forEach(r => {
                            if (response?.data?.data.gwlist.includes(r.gwcode)) {
                                arr.push(r)
                            }
                        })
                        arr.forEach(m => {
                            this.$refs.multipleTable.toggleRowSelection(m,true);
                        })
                        this.sysGwList[this.sysid] = arr;
                    }
                })

            }
        },
        chooseCity(data) {
            this.sysid = data.id
            this.getRole()
        },
        open2(row) {
            this.from2 = {
                new: '',
                once: '',
                usrcode: row.usrcode
            }
            this.dialogVisible2 = true
        },
        del(val) {
            if (val.length == 0) {
                return
            }
            this.$confirm(this.$t('是否继续删除'), ' ', {
                confirmButtonText: this.$t('determine'),
                cancelButtonText: this.$t('取消'),
                type: 'warning'
            }).then(() => {
                const res = axios(this.getOptions({
                    apiId: 'dlgclassrun',
                    dbid: '01',
                    btn: '{"cmd":"DLG","name":"人员删除/作废","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*218;0;0"}',
                    usercode: sessionStorage.getItem('rym_user'),
                    env: JSON.stringify({
                        "usrcode": val
                    })
                }))
                res.then(response => {
                    this.$message.success(this.$t('操作成功'));
                    this.getData(1)
                })
            }).catch(() => {});
        },
        clickSure2() {
            if (!this.from2.new) {
                return
            }
            if (this.from2.new == this.from2.once) {
                const res = axios(this.getOptions({
                    apiId: 'dlgclassrun',
                    dbid: '01',
                    btn: '{"cmd":"DLG","name":"人员-修改密码","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*212;0;0"}',
                    usercode: sessionStorage.getItem('rym_user'),
                    env: JSON.stringify({
                        "rPwd": btoa(encodeURIComponent(this.from2.new)),
                        "usercode": this.from2.usrcode
                    })
                }))
                res.then(response => {
                    this.$message.success(this.$t('操作成功'));
                    this.getData(this.totalPage)
                    this.dialogVisible2 = false
                })
            } else {
                this.$message.error(this.$t('俩次新密码不一致'));
            }
        },
        getData(page) {
            this.table_edit = 0
            this.totalPage = page
            const res = axios(this.getOptions({
                apiId: 'dlgclassrun',
                dbid: '01',
                btn: '{"cmd":"DLG","name":"获取人员列表-公司","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*202;0;0"}',
                usercode: sessionStorage.getItem('rym_user'),
                env: JSON.stringify({
                    page: {
                        page: this.totalPage,
                        pagesize: 10
                    }
                })
            }))
            res.then(response => {
                if (response?.data?.data?.data) {
                    this.tableData = response.data.data.data
                    this.total = response.data.data.total
                } else {
                    this.$message.error(response.data.message);
                    this.totalPage = 1
                }
            })
        },
        init() {
            const res = axios(this.getOptions({
                apiId: 'dlgclassrun',
                dbid: '01',
                btn: '{"cmd":"DLG","name":"获取组织机构","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*200;0;0"}',
                usercode: sessionStorage.getItem('rym_user')
            }))

            res.then(response => {
                this.data = response?.data?.data?.value
                this.getList()
            })
        },
        getOptions(data) {
            return {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data: {
                    ...data,
                    "snkey": sessionStorage.getItem('rym_snkey')
                },
                url: this.$store.state.host + '/entrance/sysapi',
            }
        },
        addClick() {
            this.checkedNodes = this.from.obj || []
            this.checked = this.from.orgcode ? this.from.orgcode.split('、') : []
            this.dialogVisible = true
            setTimeout(() => {
                this.$refs.tree.setCheckedKeys(this.checked);
            }, 1000)
        },
        clickSure() {
            let arr = []
            let arr2 = []
            this.from.obj = []
            this.checkedNodes.forEach(r => {
                arr.push(r.orgcode)
                arr2.push(r.orgname)
                this.from.obj.push(r)
            })
            this.from.orgcodeN = arr2.join('、')
            this.from.orgcode = arr.join('、')
            this.dialogVisible = false
        },
        closeDia() {
            this.dialogVisible = false
            this.checkedNodes = []
            this.checked = []
            this.$refs.tree.setCheckedKeys([]);
        },
        addUser() {
            this.from = {
                id: '',
                obj: [],
                orgcodeN: ''
            }
            this.sysid = ''
            this.fj_root = ''
            this.fileList = []
            this.tableData2 = []
            this.multipleSelection = []
            this.sysGwList = {};
            this.value = this.accountList[0].image
            this.table_edit = 1
        },
        editUser(data) {
            this.from = {
                ...data,
                id: data.usrcode,
                obj: [],
                orgcodeN: data.orgname
            }
            this.sysid = ''
            this.fj_root = ''
            this.fileList = []
            this.multipleSelection = []
            this.sysGwList = {};
            this.tableData2 = []
            this.value = this.accountList[0].image
            if (data.account_type) {
                this.accountList.forEach(r => {
                    if (r.sid == data.account_type) {
                        this.value = r.image
                    }
                })
            }
            if (data.photo_pic) {
                this.fileList = [
                    {
                        url: this.$store.state.host + '/entrance' + data.photo_pic
                    }
                ]
                this.fj_root = data.photo_pic.split('/db_01/')[1]
            }
            this.table_edit = 2
        },
        onExceed() {
            this.$message.error(this.$t('只能上传一张图片'));
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type.indexOf('image') > -1;
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error(this.$t('只能上传图片'));
            }
            if (!isLt2M) {
                this.$message.error(this.$t('2MB'));
            }
            return isJPG && isLt2M;
            // return isLt2M
        },
        //查看
        handlePreview(file) {
            window.open(this.fileList[0].url)
        },
        //上传
        upload(file) {


            let formData = new FormData(); // 创建FormData对象
            formData.append('file', file.file); // 添加文件到formData

            axios({
                method: 'post',
                url: this.$store.state.host + '/entrance///sysupd?updid=40&dbid=01&cellID=_USERIMG&snkey=' + sessionStorage.getItem('rym_snkey'),
                data: formData,
                responseType: 'json',
                withCredentials: false,
                headers: { 'Content-Type': 'multipart/form-data' } // 设置请求头为multipart/form-data类型
            })
                .then((response) => {
                    this.fj_root = response.data.data.fj_root
                    this.fileList = [
                        {
                            url: this.$store.state.host + '/entrance/db_01/' + this.fj_root
                        }
                    ]
                })
                .catch((error) => {
                    console.error(error); // 处理错误信息
                });
        },
        // 删除
        handleRemove(file) {
            this.fileList = []
            this.fj_root = ''
        },
        checkChange(val, obj, data) {
            let arr = [val]
            // this.nodeList.forEach(r => {
            //     if (obj.checkedKeys.includes(r.orgcode)){
            //         arr.push(r)
            //     }
            // })
            this.checkedNodes = arr
            this.$refs.tree.setCheckedKeys([val.orgcode]);
        },
        tagClose(id) {
            let arr = []
            let list = []
            this.checkedNodes.forEach(r => {
                if (r.orgcode != id) {
                    arr.push(r.orgcode)
                    list.push(r)
                }
            })
            this.checkedNodes = list
            this.$refs.tree.setCheckedKeys(arr);
        },
        getList() {
            let arr = []
            this.data.forEach(r => {
                if (r.child && r.child.length) {
                    r.child.forEach(m => {
                        if (m.child && m.child.length) {
                            m.child.forEach(n => {
                                if (n.child && n.child.length) {} else {
                                    arr.push(n)
                                }
                            })
                        } else {
                            arr.push(m)
                        }
                    })
                } else {
                    arr.push(r)
                }
            })
            this.nodeList = arr
        },
        getId(image) {
            let val = ''
            this.accountList.forEach(r => {
                if (r.image == image) {
                    val = r.sid
                }
            })
            return val
            
        },
        sure() {
            if (this.fileList.length == 0) {
                this.$message.error(this.$t('请上传账户头像'));
                return
            }
            if (!this.from.tel) {
                this.$message.error(this.$t('请填写手机号'));
                return
            }
            if (!this.from.usrname) {
                this.$message.error(this.$t('请填写用户名'));
                return
            }
            if (!this.from.semail) {
                this.$message.error(this.$t('请填写邮箱'));
                return
            }
            if (!this.from.orgcode) {
                this.$message.error(this.$t('请选择部门'));
                return
            }
            if (this.btn_loading) {
                return false
            }
            this.btn_loading = true
            if (this.table_edit == 2) {
                const res2 = axios(this.getOptions({
                    apiId: 'dlgclassrun',
                    dbid: '01',
                    btn: '{"cmd":"DLG","name":"人员-修改","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*206;0;0"}',
                    usercode: sessionStorage.getItem('rym_user'),
                    env: JSON.stringify({
                        usrname: this.from.usrname,
                        // password: btoa(encodeURIComponent(123456)),
                        orgcode: this.from.orgcode,
                        sex: 1,
                        semail: this.from.semail,
                        ident: '',
                        tel: this.from.tel,
                        usrcode: this.from.usrcode,
                        photo_pic: this.fj_root,
                        account: this.from.account,
                        account_type: this.getId(this.value),
                        sysGwList:this.sysGwList
                    })
                }))
                res2.then(response => {
                    this.$message.success(this.$t('操作成功'));
                    this.btn_loading = false
                    this.getData(1)
                })
            } else {
                const res2 = axios(this.getOptions({
                    apiId: 'dlgclassrun',
                    dbid: '01',
                    btn: '{"cmd":"DLG","name":"人员-新增","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*205;0;0"}',
                    usercode: sessionStorage.getItem('rym_user'),
                    env: JSON.stringify({
                        usrname: this.from.usrname,
                        password: btoa(encodeURIComponent(123456)),
                        orgcode: this.from.orgcode,
                        sex: 1,
                        semail: this.from.semail,
                        ident: '',
                        tel: this.from.tel,
                        // usrcode: this.from.usrcode,
                        photo_pic: this.fj_root,
                        account: this.from.account,
                        account_type: this.getId(this.value),
                        sysGwList:this.sysGwList
                    })
                }))
                res2.then(res=> {
                    console.log(res)
                    if(res.data.id ==0){
                        this.from.id = res.data.data.user.usrcode
                        this.from.usrcode = res.data.data.user.usrcode
                        this.$message.success(this.$t('操作成功'));
                        this.table_edit = 2;
                    }else{
                        this.$message.success(res.data.message);
                    }
                    setTimeout(() => {
                        this.btn_loading = false
                        this.getData(1)
                    }, 500);
                })
            }
        }
    }
}
</script>


<style>
@import url(../assets/user.css);
</style>